<template>
  <component
    :is="componentName(element, components)"
    v-for="element in pageElements"
    :key="element.id"
    :element="element"
  />
</template>

<script setup lang="ts">
  const { componentName } = useAlchemy()
  const props = defineProps<{
    page: AlchemyPage
  }>()

  const saleStore = useSaleStore()
  const sale = computed(() => saleStore.activeSale)

  const pageElements = computed(() => {
    if (!props.page) {
      return []
    }
    const pageHeroIndex = Array.from(props.page.elements)
      .map((el) => el.name)
      .indexOf("page_hero")

    const pageHeroAltIndex = Array.from(props.page.elements)
      .map((el) => el.name)
      .indexOf("page_hero_alternate")

    const filteredElements = Array.from([...props.page.elements])
    if (sale.value) {
      if (pageHeroIndex >= 0) {
        filteredElements.splice(pageHeroIndex, 1)
      }
      if (pageHeroAltIndex >= 0) {
        filteredElements.splice(pageHeroAltIndex, 1)
      }
      return filteredElements
    } else {
      return filteredElements
    }
  })

  const components = {
    notice: defineAsyncComponent(() => import("~/alchemy/elements/notice.vue")),
    page_hero_oneozsale: defineAsyncComponent(
      () => import("~/alchemy/elements/page_hero_oneozsale.vue"),
    ),
    panel_row: defineAsyncComponent(
      () => import("~/alchemy/elements/panel_row.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    section_with_background: defineAsyncComponent(
      () => import("~/alchemy/elements/section_with_background.vue"),
    ),
    divider: defineAsyncComponent(
      () => import("~/components/Divider/index.vue"),
    ),
    grid_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/grid_columns.vue"),
    ),
    page_hero: defineAsyncComponent(
      () => import("~/alchemy/elements/page_hero.vue"),
    ),
    page_hero_alternate: defineAsyncComponent(
      () => import("~/alchemy/elements/page_hero_alternate.vue"),
    ),
    curalate_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/curalate_embed.vue"),
    ),
    testimonials: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/index.vue"),
    ),
    testimonial: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/alchemy_testimonial.vue"),
    ),
    product_list: defineAsyncComponent(
      () => import("~/alchemy/elements/product_list.vue"),
    ),
    product_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/product_slider.vue"),
    ),
    taxon_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/taxon_slider.vue"),
    ),
    collection_oneozsale: defineAsyncComponent(
      () => import("~/alchemy/elements/collection_oneozsale.vue"),
    ),
  }
  const config = useRuntimeConfig()
  useHead(usePageInfo(props.page))
  useHead({
    script: [
      {
        type: "application/ld+json",
        innerHTML: {
          "@context": "https://schema.org",
          "@type": "Store",
          name: "CandleScience - Candle Making Supplies, Fragrances, and More",
          url: `${config.public.BASE_URL}/`,
          sameAs: [
            "https://www.tiktok.com/@candlescience",
            "https://www.instagram.com/candlescience/",
            "https://www.youtube.com/channel/UCmaglnFeg6S_Qh1IfrIuPuA",
            "https://www.facebook.com/candlescience/",
            "https://twitter.com/CandleScience",
            "https://www.pinterest.com/candlescience/",
          ],
          address: {
            "@type": "PostalAddress",
            streetAddress: "1247 Person St",
            addressLocality: "Durham",
            addressRegion: "NC",
            postalCode: "27703",
            addressCountry: "United States",
          },
          logo: `${config.public.BASE_URL}/favicon/cs-favicon76.png`,
        },
      },
    ],
  })
</script>
