<template>
  <Intro :page="page" />
</template>

<script setup>
  import { deserializePage } from "@alchemy_cms/json_api"
  import Intro from "~/alchemy/page_layouts/intro"
  const { handleError } = useErrorHandling()

  const app = useNuxtApp()
  const { api } = useApi()
  const url = app.$preview
    ? `/jsonapi/admin/pages/homepage`
    : "/jsonapi/pages/homepage"

  const { data: page } = await useAsyncData(url, async () => {
    try {
      const data = await api(url, {
        params: {
          t: app.$preview ? Date.now() : undefined,
          include: "all_elements.ingredients",
        },
      })
      return deserializePage(data)
    } catch (error) {
      handleError(error)
    }
  })
</script>
